module Designbook exposing (BookModel, main)

import Browser.Events
import Css.Global
import DX.Utilities as Tw
import Designbook.DataDisplay.DescriptionList exposing (descriptionList)
import Designbook.DesignSystem.Colours as DesignSystem
import Designbook.DesignSystem.FontAndText as DesignSystem
import Designbook.DesignSystem.Shadow as DesignSystem
import Designbook.DesignSystem.Spacing as DesignSystem
import Designbook.DesignSystemElements.Buttons as DesignSystem
import Designbook.DesignSystemElements.DatePicker as DatePicker
import Designbook.DesignSystemElements.LoadingPlaceholder as DesignSystem
import Designbook.DesignSystemElements.Modals as NewModals
import Designbook.Elements.Accordion as Accordion exposing (accordion)
import Designbook.Elements.Alerts exposing (alerts)
import Designbook.Elements.Avatars exposing (avatars)
import Designbook.Elements.Buttongroups as ButtonGroups exposing (buttongroups)
import Designbook.Elements.Buttons exposing (buttons)
import Designbook.Elements.Dropdowns as Dropdowns exposing (dropdowns)
import Designbook.Elements.EmptyState exposing (emptyState)
import Designbook.Elements.ErrorState exposing (errorState)
import Designbook.Elements.Icons exposing (icons)
import Designbook.Elements.Link exposing (links)
import Designbook.Elements.Spinner exposing (spinner)
import Designbook.Elements.Tag as Tag
import Designbook.Elements.TagSelection as TagSelection
import Designbook.Elements.Tooltip as Tooltip
import Designbook.Forms.Checkboxes exposing (checkboxes)
import Designbook.Forms.FormLayouts exposing (formLayout)
import Designbook.Forms.Forms as Forms
import Designbook.Forms.Inputs exposing (inputs)
import Designbook.Forms.SelectMenus exposing (selectMenus)
import Designbook.Forms.Toggles as Toggles exposing (toggles)
import Designbook.Helpers.Float exposing (float)
import Designbook.Helpers.Http exposing (http)
import Designbook.Helpers.UUID exposing (uuid)
import Designbook.Intro exposing (intro)
import Designbook.Layout.Containers exposing (containers)
import Designbook.Layout.Panels as Panels exposing (panels)
import Designbook.Layout.Sidebar exposing (sidebar)
import Designbook.Lists.List as Ll
import Designbook.Lists.Table as Lt
import Designbook.Navigation.Breadcrumbs exposing (breadcrumbs)
import Designbook.Overlay.ModalConfirm as ModalConfirm exposing (modalConfirm)
import Designbook.Overlay.Modals as Modals exposing (modals)
import Designbook.TechDocumentation.ErrorReporting exposing (errorReporting)
import Designbook.TechDocumentation.GettingStarted exposing (gettingStarted)
import Designbook.TechDocumentation.Scheduler exposing (scheduler)
import ElmBook exposing (withChapterGroups, withStatefulOptions, withThemeOptions)
import ElmBook.ElmCSS exposing (Book, book)
import ElmBook.StatefulOptions as StatefulOptions
import ElmBook.ThemeOptions
import Html.Styled as Html
import Html.Styled.Attributes as Attr


type alias BookModel =
    { buttongroupModel : ButtonGroups.Model
    , dropdownModel : Dropdowns.Model
    , modalModel : Modals.Model
    , confirmModalModel : ModalConfirm.Model
    , newUiModals : NewModals.Model
    , datePickers : DatePicker.Model
    , panelsModel : Panels.Model
    , selectMenusModel : Designbook.Forms.SelectMenus.Model
    , toggleModel : Toggles.Model
    , formModel : Forms.Model
    , accordionModel : Accordion.Model
    , focusTagSelectionModel : TagSelection.Model
    }


initialState : BookModel
initialState =
    { buttongroupModel = ButtonGroups.init
    , dropdownModel = Dropdowns.init
    , modalModel = Modals.init
    , confirmModalModel = ModalConfirm.init
    , newUiModals = NewModals.init
    , datePickers = DatePicker.init
    , panelsModel = Panels.init
    , selectMenusModel = Designbook.Forms.SelectMenus.init
    , toggleModel = Toggles.init
    , formModel = Forms.init
    , accordionModel = Accordion.init
    , focusTagSelectionModel = TagSelection.init
    }


main : Book BookModel
main =
    book "Designbook"
        |> withStatefulOptions
            [ StatefulOptions.initialState initialState
            , StatefulOptions.subscriptions
                [ Browser.Events.onClick
                    (ButtonGroups.onClickDecoder ButtonGroups.init.id)
                , Browser.Events.onClick
                    (Dropdowns.onClickDecoder Dropdowns.init.id)
                , Browser.Events.onClick
                    (Modals.onClickDecoder Modals.init.id)
                , Browser.Events.onClick
                    (Modals.onClickDecoder NewModals.modalDomId)
                , Browser.Events.onClick
                    (TagSelection.onClickDecoder TagSelection.init.id)
                ]
            ]
        |> withThemeOptions
            [ ElmBook.ThemeOptions.logo (Html.img [ Attr.src "/static/fiveten-white.svg" ] [])
            , ElmBook.ThemeOptions.globals [ Css.Global.global Tw.globalStyles ]
            ]
        |> withChapterGroups
            [ ( "", [ intro ] )
            , ( "Design System"
              , [ DesignSystem.colours
                , DesignSystem.spacing
                , DesignSystem.shadows
                , DesignSystem.fontAndText
                ]
              )
            , ( "Design System Elements"
              , [ DesignSystem.buttons
                , DesignSystem.loadingPlaceholder
                , NewModals.modals
                , DatePicker.view
                ]
              )
            , ( "Elements"
              , [ avatars
                , alerts
                , buttons
                , buttongroups
                , dropdowns
                , icons
                , links
                , spinner
                , accordion
                , Tag.tag
                , Tooltip.tooltip
                , TagSelection.tagSelections
                , emptyState
                , errorState
                ]
              )
            , ( "Forms"
              , [ formLayout
                , inputs
                , checkboxes
                , selectMenus
                , toggles
                , Forms.forms
                ]
              )
            , ( "Data Display", [ descriptionList ] )
            , ( "Lists", [ Lt.table, Ll.listChapter ] )
            , ( "Overlay"
              , [ modals
                , modalConfirm
                ]
              )
            , ( "Navigation", [ breadcrumbs ] )
            , ( "Layout"
              , [ containers
                , sidebar
                , panels
                ]
              )
            , ( "Helpers"
              , [ float
                , uuid
                , http
                ]
              )
            , ( "Technical Documentation", [ gettingStarted, scheduler, errorReporting ] )
            ]
